

























































import { Component } from 'vue-property-decorator';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import WidgetMixins from '@/mixins/WidgetMixins';
import { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
extend('email', {
  ...email,
  message: 'Email is not valid',
});
extend('required', {
  ...required,
  message: 'Field is required',
});

@Component({
  name: 'ForgotPassword',
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar: loadWidget('widgets/SnackBar'),
  },
})
export default class ForgotPassword extends mixins(WidgetMixins) {
  email?: string | null = '';
  isSuccessful: boolean = false;

  forgotPassword(): void {
    this.$store.dispatch('auth/forgotPassword', { email: this.email });
  }
}
